import styled from 'styled-components';
import BaseModal from './BaseModal';
import PrimaryButton from '../common/components/PrimaryButton';
import { FaCheck } from 'react-icons/fa';
import { GREEN_COLOR, LIGHT_GREY_COLOR, PRIMARY_COLOR } from '../constants';
import { desktopMediaQuery } from '../styleHelpers';

interface WelcomeModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const WelcomeModal = ({ showModal, setShowModal }: WelcomeModalProps) => {
  const handleClose = () => setShowModal(false);

  return (
    <Modal showModal={showModal} onRequestClose={handleClose}>
      <Container>
        <Title>Welcome! 👋</Title>
        <Content>
          <MessageText>
            This is the sister site of UncensoredAI.io! For the complete experience with all features, please visit our main platform:
          </MessageText>
          <LinkContainer>
            <MainSiteLink 
              href="https://www.uncensoredai.io" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              www.uncensoredai.io
            </MainSiteLink>
          </LinkContainer>
        </Content>
        <PrimaryButton onClick={handleClose}>
          <FaCheck color={GREEN_COLOR} />
          Got it!
        </PrimaryButton>
      </Container>
    </Modal>
  );
};

const Modal = styled(BaseModal)`
  display: flex;
  z-index: 3;
`;

const Container = styled.div`
  width: 95%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h2`
  text-align: center;
  margin: 0;
`;

const Content = styled.div`
  padding: 16px;
  border: 1.5px solid ${LIGHT_GREY_COLOR};
  border-radius: 8px;
  
  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${PRIMARY_COLOR};
    }
  }
`;

const MessageText = styled.p`
  margin: 0 0 16px 0;
  line-height: 1.5;
  font-size: 1.1rem;
`;

const LinkContainer = styled.div`
  text-align: center;
`;

const MainSiteLink = styled.a`
  color: ${PRIMARY_COLOR};
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 6px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${LIGHT_GREY_COLOR};
    text-decoration: underline;
  }
`;

export default WelcomeModal; 